@import "./styles/_constants.scss";
@import "./styles/ta.scss";
/* You can add global styles to this file, and also import other style files */

textarea, select, input, button, button:focus { outline: none; }

::selection, ::-moz-selection {
  background: #FED510;
  color: black;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

// To unset the ng-zorro selection style

.ProseMirror-hideselection .ProseMirror-selectednode::selection {
  color: initial !important;
}
.ProseMirror-hideselection .ProseMirror-selectednode::-moz-selection {
  color: initial !important;
}

.ProseMirror-hideselection .ProseMirror-selectednode span::selection {
  color: initial !important;
}
.ProseMirror-hideselection .ProseMirror-selectednode span::-moz-selection {
  color: initial !important;
}

.ProseMirror-hideselection .ProseMirror-selectednode code::selection {
  color: initial !important;
}
.ProseMirror-hideselection .ProseMirror-selectednode code::-moz-selection {
  color: initial !important;
}

.ProseMirror-hideselection .ProseMirror-selectednode strong::selection {
  color: initial !important;
}
.ProseMirror-hideselection .ProseMirror-selectednode strong::-moz-selection {
  color: initial !important;
}
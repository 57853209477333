@import "./_mixins.scss";

$color-blue: #00A0CB;

// Colors
$color-white: #FFFFFF !default;
$color-black: #000 !default;
$color-orange: #FF9100 !default;
$color-green: #28CB00 !default;
$color-pink: #CE0078 !default;
$color-yellow: #FED510 !default;

//Base Grey Color
$color-master: #EFEDE7 !default;

// Lighter Grey colors
$color-master-light: #e8e8e8 !default;
$color-master-lighter: #efefef !default;
$color-master-lightest: #fafafa !default;

// Darker Grey colors
$color-master-dark:mix($color-master, $color-black, 45%) !default;
$color-master-darker:mix($color-master, $color-black, 27%) !default;
$color-master-darkest:mix($color-master, $color-black, 18%) !default;

// Lighter Blue colors
$color-blue-light:mix($color-blue, $color-white, 16%) !default;
$color-blue-lighter:mix($color-blue, $color-white, 9.5%) !default;
$color-blue-lightest:mix($color-blue, $color-white, 3%) !default;

// Darker Blue colors
$color-blue-dark:mix($color-blue, $color-black, 45%) !default;
$color-blue-darker:mix($color-blue, $color-black, 27%) !default;
$color-blue-darkest:mix($color-blue, $color-black, 18%) !default;

// Lighter Pink colors
$color-pink-light:mix($color-pink, $color-white, 16%) !default;
$color-pink-lighter:mix($color-pink, $color-white, 9.5%) !default;
$color-pink-lightest:mix($color-pink, $color-white, 3%) !default;

// Darker Pink colors
$color-pink-dark:mix($color-pink, $color-black, 45%) !default;
$color-pink-darker:mix($color-pink, $color-black, 27%) !default;
$color-pink-darkest:mix($color-pink, $color-black, 18%) !default;

// Shadows
@mixin shadow-1 {
  @include box-shadow(1px 10px 30px rgba(0,0,0,0.12));
}

// Cards
$card-radius:10px;

.ta-blue-color {
  color: $color-blue;
}
.ta-pink-color {
  color: $color-pink;
}
.ta-green-color {
  color: $color-green;
}
.ta-yellow-color {
  color: $color-yellow;
}
.ta-blue-bg {
  background-color: $color-blue;
}
.ta-master-light-bg {
  background-color: $color-master-light;
}

// Ticket colors
$states: playing, pause, done, closed, archived, snoozed, helpneeded, pending;
$state-colors: $color-orange, $color-master-dark, $color-blue, $color-green, $color-pink, $color-master-dark, $color-pink, $color-orange;
@for $i from 1 through length($states) {
  $current-state: nth($states, $i);
  .ta-#{$current-state}-bg {
      background-color: nth($state-colors, $i);
  }
  .ta-#{$current-state}-color {
      color: nth($state-colors, $i);
  }
  .ta-#{$current-state}-border-color {
      border-color: nth($state-colors, $i);
  }
}
@import "./_constants.scss";
@import "./_mixins.scss";

.ant-card-head {
  @include border-top-radius($card-radius);
}

nz-page-header {
  .ant-page-header-heading-title {
    max-width: 100%;
  }
}

nz-upload {
  .ant-upload-list-item-name {
    color: $color-blue;
  }
}

nz-collapse {
  .ant-collapse-borderless {
    background-color: transparent;
  }
}

nz-input-group.no-border {
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  box-shadow: none;
  input {
      background-color: transparent;
      border: none;
      outline: none;
      -webkit-appearance: none;
      box-shadow: none;
  }
}
nz-select.no-border {
  .ant-select-selector {
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
    box-shadow: none;
  }
}

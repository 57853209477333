@import "./_constants.scss";
@import "./_mixins.scss";

.ta-card {
  padding: 32px;
  background-color: $color-white;

  &.ta-card-nopadding {
    padding: 0px;
  }

  &.ta-card-radius {
    overflow: hidden;
    @include border-radius($card-radius);
  }

  &.ta-card-shadow {
    @include shadow-1();
  }

  &.ta-card-border {
    border: 1px solid $color-master-light;
  }
  &.ta-card-dark {
    background-color: $color-master-darker;
    color: $color-white;
  }
  &.ta-card-blue {
    background-color: $color-blue;
    color: $color-white;
  }

  .ta-cardheader { 
    @include border-top-radius($card-radius);
    padding-top: 12px;
    padding-right: 0px;
    padding-bottom: 12px;
    padding-left: 0px;
    font-size: 14px;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
  }
}

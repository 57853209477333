.visible-on-hover-container {
    .visible-on-hover {
        visibility: hidden;
    }

    &:hover {
        .visible-on-hover {
            visibility: visible;
        }
    }
}

.button-right-space {
    margin-right: 8px;
}

.muted-block {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    // opacity: 0.5;
}

.emoji-avatar {
    width: 22px;
    height: 22px;
    padding: 5px;
    padding-top: 0px;
    border-radius: 10px;
    background: #eee;
    display: inline-block;
}

/* Hide scrollbar for IE, Edge and Firefox */
.on-scrollbar {
    scrollbar-width: thin;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
}

.on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
}

.on-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    background-color: transparent;
}

.on-scrollbar::-webkit-scrollbar-thumb {
    background-color: #acacac;
}

div * {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 3px !important;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}
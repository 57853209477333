@import "../node_modules/ng-zorro-antd/ng-zorro-antd.compact.less";

// The prefix to use on all css classes from ant.
@ant-prefix             : ant;
@font-size-base         : 14px;
// CUSTOM
@blue-6                 : #00A0CB;
@red-6                  : #CE0078;
@green-6                : #28CB00;
@gold-6                 : #FF9100;
@normal                 : #EFEDE7;
// -------- Colors -----------
@primary-color          : @blue-6;
@info-color             : @blue-6;
@success-color          : @green-6;
@processing-color       : @blue-6;
@error-color            : @red-6;
@highlight-color        : @red-6;
@warning-color          : @gold-6;
@normal-color           : @normal;

@layout-header-height   : 44px;
@menu-item-active-border-width: 3px;
@menu-item-active-bg    : 'none';
@border-radius-base     : 10px;

@body-background        : @normal;
@layout-body-background : @normal;

@font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

button.ant-btn-sm { // Fix due to issue on font size change with compact theme
    line-height: 20px;
}

.ant-layout-sider {
    background: white; // Fix sidebar color
}
.anticon {
    vertical-align: -0.15em;
    svg {
        overflow: initial;
        vertical-align: initial;
    }
}
/*------------------------------------------------------------------
[5. Typography]
*/

/* Standard elements
--------------------------------------------------
*/

/* To Load Hinted Fonts for Windows */
@font-face {
    font-family: FuturaBT;
    src: url(/assets/fonts/FuturaBT/FuturaBT-Book.woff);
    font-weight: normal;
}
@font-face {
    font-family: FuturaBT;
    src: url(/assets/fonts/FuturaBT/FuturaBT-Light.woff);
    font-weight: lighter;
}
@font-face {
    font-family: FuturaBT;
    src: url(/assets/fonts/FuturaBT/FuturaBT-Heavy.woff);
    font-weight: bold;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600');

@import "./_constants.scss";

$body-font-family: FuturaBT, "Helvetica Neue", Helvetica, Arial, sans-serif;
.text-muted {
    color: $color-master-dark;
}
.font-futura {
    font-family: $body-font-family;
}
.font-futura-bold {
    font-family: $body-font-family;
    font-weight: bold;
}
.font-futura-normal {
    font-family: $body-font-family;
    font-weight: normal;
}
.font-futura-light {
    font-family: $body-font-family;
    font-weight: lighter;
}
body {
    color: $color-master-darker;
}
a {
    text-decoration: none;
    &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
}
.ta-link {
    color: $color-blue;
}
.cursor-pointer {
    cursor: pointer;
}

.ta-highlight-em {
    em {
        background-color: $color-yellow;
        font-style: inherit;
    }
}

em.ta-highlight {
    background-color: $color-yellow;
    font-style: inherit;
}

.ant-form-item-label label {
    // width: 100%;
    white-space: pre-wrap;
    line-height: initial;
    // display: inline-block;
}

.ta-line-underline {
    background-image: url('/assets/undeline-small.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
}

.ta-line-underline-large {
    background-image: url('/assets/undeline-large.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
}
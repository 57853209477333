.iframe-sim {
  position: relative;
  width: 100%;

  .iframe-sim-container {
    display: block;
    all: initial;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: small;
    line-height: 1.5;
    font-family: Arial, Helvetica, sans-serif;
    color: #222;

    // * {
    //   all: unset;
    // }
    div {
      display: block;
    }

    ul,
    ol {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      object-fit: cover;
    }

    color: black;
  }

}